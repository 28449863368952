<template>
    <div>
        <custom-header :title="error_received ? '' : title">
            <b-alert :show="error_received" class="container" variant="danger">
                There was an error while fetching the file.<br>Please try again later.
            </b-alert>

            <p v-show="!error_received">{{ time_remaining }} seconds remaining</p>

            <b-button v-show="!error_received" :class="{'disabled': !continue_enabled}" variant="info">Continue
            </b-button>
            <b-button v-show="error_received && error_button.visible" variant="info">Continue</b-button>
        </custom-header>
    </div>
</template>

<script>
import {BAlert, BButton} from 'bootstrap-vue';
import CustomHeader from "@/components/templates/CustomHeader";

export default {
    name: "Download",
    components: {
        BAlert,
        BButton,
        CustomHeader
    },
    metaInfo: {
        title: "Download"
    },
    data() {
        return {
            error_received: false,
            error_button: {
                visible: true,
                text: "Try again",
                action: "reload"
            },
            title: "Your file is almost ready",
            time_remaining: 10,
            continue_enabled: false,


        }
    },
    created() {

        const countdown_interval = setInterval(() => {
            if (document.hasFocus()) {
                this.time_remaining--;
                if (this.time_remaining === 0) {
                    clearInterval(countdown_interval);
                    this.continue_enabled = true;
                }
            }
        }, 1000);

    }
}
</script>

<style scoped>

</style>